<template>
  <div
    v-if="data.position === 'center'"
    :class="`picking-hints fixed-center full-width ${bg}`"
  >
    {{ $t(data.text) }}
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'PickingMessage',
  props: {
    rawData: {
      type: Object,
      default () {
        return {}
      }
    },
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      helper: null
    }
  },
  computed: {
    bg () {
      if (this.data.bg) {
        return `bg-${this.data.bg}`
      }

      return ''
    }
  },
  watch: {
    data: {
      handler (newVal) {
        if (this.helper) {
          this.removeHelper(this.helper.id)
        }

        if (newVal.position !== 'center') {
          this.addHelper(newVal.text)
            .then(helper => {
              this.helper = helper
            })
        }
      },
      deep: true
    }
  },
  mounted () {
    if (this.data.position !== 'center') {
      this.addHelper(this.data.text)
        .then(helper => {
          this.helper = helper
        })
    }
  },
  unmounted () {
    if (this.helper) {
      this.removeHelper(this.helper.id)
    }
  },
  methods: {
    ...mapMutations([
      'removeHelper',
      'setLastHelper'
    ]),
    ...mapActions([
      'addHelper',
      'reactivateHelper'
    ])
  }
}
</script>

<style>
.picking-hints {
  text-align: center;
  font-size: 1rem;
  animation: fadein 1.5s infinite;
  width: 100%;
}

.picking-hints.fixed-bottom {
  bottom: 90px;
}
</style>
